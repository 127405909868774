// ** Router imports
import {Fragment, lazy, useEffect, useState} from 'react'

// ** Router imports
import {useRoutes, Navigate, useLocation} from 'react-router-dom'

// ** GetRoutes
//import {getRoutes} from './routes'

// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'

// ** Utils
import {isObjEmpty, generateUUID} from '@src/utility/Utils'

// ** Hooks Imports
import {useLayout} from '@hooks/useLayout'

import dayjs from 'dayjs'

import {useAtom, useAtomValue, useSetAtom} from 'jotai' //, useSetAtom, useAtomValue
import {
  areasLstAtom,
  configZeroObjAtom,
  contactsLstAtom,
  curActionNameAtom,
  curFunctNameAtom,
  errorMsgAtom,
  genericLstAtom,
  genericObjAtom,
  isLoadingAtom,
  isLoadingObjAtom,
  isLoggedAtom,
  isMaintenanceBlockAtom,
  isMqttAtom,
  isMqttConnectedAtom,
  isSavingAtom,
  loggedUserAtom,
  memoryLstAtom,
  mqttConnAtom,
  outputsLstAtom,
  simIMEISerialAtom,
  statusDetailAtom,
  statusGetAtom,
  statusGetZero,
  usersLstAtom,
  versionAtom,
  inputLstAtom,
  nextVersionAtom,
  devicesLstAtom,
  rtspsLstAtom,
  imgAtom,
  nextVersionTestAtom
} from '@src/utility/atoms'

import {connect as ConnectMqtt} from 'rsup-mqtt'
import Constant from '@src/utility/Constants'
import {typeOutput} from '@src/data/staticData'
import Utility from '@src/utility/utility'
import ColorsGesco from '@src/utility/ColorsGesco'
import Debug from '@src/views/Debug'

import Agent from '../utility/agent'
import {useResetAtom} from 'jotai/utils'

const uniqueId = `w-${generateUUID().substring(0, 6)}`

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />
}

// ** Default Route
export const DefaultRoute = '/home'

const Home = lazy(() => import('../views/Home'))
const Memory = lazy(() => import('../views/Memory'))
const Login = lazy(() => import('../views/Login'))
const Logout = lazy(() => import('../views/Logout'))
const GenericListEditing = lazy(() => import('../views/GenericListEditing'))
const Error = lazy(() => import('../views/Error'))

let client: any
let timeout: any

let statusGet: any = {...statusGetZero}

const Router = () => {
  // ** Hooks
  const {layout} = useLayout()

  const [numeriCode, setNumeriCode] = useState<string>('')

  const [isLogged] = useAtom(isLoggedAtom)
  const [isMqtt] = useAtom(isMqttAtom)
  const [mqttConnectionObj] = useAtom(mqttConnAtom)

  const setVersion = useSetAtom(versionAtom)
  const setIsMqttConnected = useSetAtom(isMqttConnectedAtom)
  const [, setLoggedUser] = useAtom(loggedUserAtom)
  const resetLoginDoLogoff = useResetAtom(loggedUserAtom)
  const [userToken, setUserToken] = useState<string>('')

  const setIsLoading = useSetAtom(isLoadingAtom)
  const setIsLoadingObj = useSetAtom(isLoadingObjAtom)
  const setIsSaving = useSetAtom(isSavingAtom)

  //const [currFunctName2] = useAtom(curFunctNameAtom)
  const currFunctName = useAtomValue(curFunctNameAtom)
  const currActionName = useAtomValue(curActionNameAtom)

  const setMemoryList = useSetAtom(memoryLstAtom)
  const setLstAreas = useSetAtom(areasLstAtom)
  const setLstUsers = useSetAtom(usersLstAtom)
  const setLstContacts = useSetAtom(contactsLstAtom)
  const setLstOutputs = useSetAtom(outputsLstAtom)
  const setIsMaintenanceBlock = useSetAtom(isMaintenanceBlockAtom)
  const setNextVersion = useSetAtom(nextVersionAtom)
  const setNextVersionTest = useSetAtom(nextVersionTestAtom)

  const setStatusMsg = useSetAtom(statusGetAtom)
  const setStatusDetailMsg = useSetAtom(statusDetailAtom)

  const setGenericList = useSetAtom(genericLstAtom)
  const setGenericObj = useSetAtom(genericObjAtom)
  const setConfigZeroObj = useSetAtom(configZeroObjAtom)
  const setGenericErrorMsg = useSetAtom(errorMsgAtom)

  const setDevicesList = useSetAtom(devicesLstAtom)
  const setRtspUrisList = useSetAtom(rtspsLstAtom)

  const setLstInputs = useSetAtom(inputLstAtom)

  const setImgGeneric = useSetAtom(imgAtom)

  const usel = useLocation()

  const SendCmd = async (msgToSend: any, isAvoidToken: boolean = false) => {
    console.log({
      name: 'sendcmd',
      msgToSend,
      mqttConnectionObj,
      isconnected: client?.isConnected(),
      isMqtt,
      currActionName
    })

    if (typeof msgToSend != 'string') {
      //add login token ...
      if (msgToSend.f == Constant.F_USERS && msgToSend.a == Constant.A_CHECK) {
        //Do nothing
        setUserToken('')
      } else {
        if (!isAvoidToken) {
          msgToSend.t = userToken
        }
      }

      msgToSend = JSON.stringify(msgToSend)
    }

    setIsLoading(true)

    const msgObj = JSON.parse(msgToSend)

    if (msgObj.f == Constant.F_IP || msgObj.f == Constant.F_ONVIF) {
      setDevicesList([])
    }

    if (msgObj.f == Constant.F_RTSP) {
      setRtspUrisList([])
    }

    if (msgObj.f == Constant.F_CAMERAS) {
      setImgGeneric('')
    }

    if (isMqtt) {
      console.log('SendCmd', client != null, msgToSend)

      if (!client?.isConnected()) {
        await mqttConnectAndSubscribe()
      }

      if (client != null && client.isConnected() && mqttConnectionObj != null) {
        const configPubSub = `${mqttConnectionObj.typeAS}/${mqttConnectionObj.mqttTopic}/${uniqueId}/${Constant.MQTT_CONFIG}`
        console.log('sendMqtt', configPubSub, msgToSend)
        client.publish(configPubSub, msgToSend, {
          qos: Constant.MQTT_QOS
        })
      }
    } else {
      //HTTP Connection
      console.log('send msg via http ...', msgToSend)
      const retObj = await Agent.postJsonData('adm/post', msgToSend)
      if (retObj != null) {
        let type = 'resp'
        if (
          retObj.indexOf(Constant.F_VERSION) >= 0 &&
          retObj.indexOf(Constant.A_GET) >= 0
        ) {
          type = 'push'
        }

        parseAndUseArrivedMessage(retObj, 'http', type)
      }
    }
  }

  const parseSecToMMSS = (value: number) => {
    if (value == 0) {
      return '00:00'
    } else {
      const min: number = Math.floor(value / 60)
      const sec: number = value % 60

      return `${min.toString().padStart(2, '0')}:${sec
        .toString()
        .padStart(2, '0')}`
    }
  }

  const setCmdStatus = (message: any) => {
    console.log({msgs: message})

    if (statusGet != null && statusGet.cmd != null) {
      if (message.p.idx == 1) {
        statusGet.cmd.c1 = message.p.state
      }
      if (message.p.idx == 2) {
        statusGet.cmd.c2 = message.p.state
      }
      if (message.p.idx == 3) {
        statusGet.cmd.c3 = message.p.state
      }
      if (message.p.idx == 4) {
        statusGet.cmd.c4 = message.p.state
      }
    }

    statusGet = {...statusGet}

    setStatusMsg(statusGet)
  }

  const setStatusGet = (message: any) => {
    let title = ''
    if (message.p.ala == 'a') {
      title = 'Allarme intrusione '
    } else if (message.p.ala == 'm') {
      //manomissione
      title = 'Allarme manomissione'
    } else if (message.p.ala == 'i') {
      //incendio
      title = 'Allarme incendio'
    } else if (message.p.ala == 's') {
      //silenzioso
      title = 'Allarme silenzioso'
    } else if (message.p.ala == 'p') {
      //panico
      title = 'Allarme panico'
    } else if (message.p.ala == '') {
      //fine allarme
      title = ''
    }

    console.log({msgs: message})

    statusGet = {...statusGet, ...message}

    setStatusMsg(statusGet)
  }

  //TODO:1 change for varbox ...
  const parseAndUseArrivedMessage = (
    message: any,
    type: string,
    respPush: string
  ) => {
    console.log({
      name: 'parseAndUseArrivedMessage',
      message,
      type,
      currFunctName,
      respPush
    })

    if (typeof message == 'string' && message != '') {
      try {
        message = JSON.parse(message)
      } catch (error) {
        console.log(error, message)
      }
    }

    if (message == null || message.f == null) {
      return ''
    }

    setIsLoading(false)

    if (respPush == 'resp') {
      if (message.f == Constant.F_USERS && message.a == Constant.A_CHECK) {
        if (message.t != null && message.t != '') {
          setUserToken(message.t)
          setLoggedUser(message.p)
        } else {
          setGenericErrorMsg('Attenzione utente non valido!')
          setUserToken('')
          resetLoginDoLogoff()
          return
        }
      } else if (
        message.f == Constant.F_MEMORY &&
        message.a == Constant.A_GET &&
        message?.p?.ret == null
      ) {
        setMemoryList(message.memory)
        setIsLoadingObj(false)
        return
      } else if (
        message.f == Constant.F_SERVICE_AREA &&
        message.a == Constant.A_GET_LIST
      ) {
        setLstAreas(message.p)
      } else if (
        message.f == Constant.F_USERS &&
        message.a == Constant.A_GET_LIST
      ) {
        setLstUsers(message.p)
      } else if (message.f == Constant.F_BLOCK && message.a == Constant.A_GET) {
        setIsMaintenanceBlock(message.p.block)
      } else if (
        message.f == Constant.F_STATUS &&
        message.a == Constant.A_DETAIL
      ) {
        console.log('status det', message)

        if (message.p.ret != '') {
          return
        }

        const lstObjForPopUp = message.p.map((item: any, i: number) => {
          const ite = {...item}

          console.log('ite ', ite)

          if (ite.k == 'w' || ite.k == 'y') {
            ite.icon = 'warning'
            ite.icontype = 'material'
            ite.iconColor = ColorsGesco.yellow800
          } else if (ite.k == 'i' || ite.k == 'g') {
            ite.icon = 'information-outline'
            ite.icontype = 'material-community'
            ite.iconColor = ColorsGesco.green800
          } else if (ite.k == 'm') {
            ite.icon = 'wrench-outline'
            ite.icontype = 'material-community'
            ite.iconColor = ColorsGesco.blue800
          } else if (ite.k == 'e' || ite.k == 'r') {
            ite.icon = 'error-outline'
            ite.icontype = 'material'
            ite.iconColor = ColorsGesco.red800
          } else if (ite.k == 'a') {
            ite.icon = 'alarm-light-outline'
            ite.icontype = 'material-community'
            ite.iconColor = ColorsGesco.red800
          } else if (ite.k == 't') {
            ite.icon = 'thermometer-lines' //'temperature-celsius'
            ite.icontype = 'material-community' //'material-community'
            ite.iconColor = ColorsGesco.yellow800
          }

          if (ite.t != null) {
            ite.t = Utility.replaceOperatorName(ite.t || '')

            ite.t = ite.t.replace('zone regolari', 'ingressi regolari')

            if (
              ite.t.indexOf('Alimentazioni corrette:') >= 0 ||
              ite.t.indexOf('Centrale assenza tensione di rete:') >= 0 ||
              ite.t.indexOf('tensione batteria errata:') >= 0
            ) {
              const regExpmV = /(\d+)mV/

              const regArray = regExpmV.exec(ite.t)

              console.log('regexp', regArray)

              if (regArray != null && regArray[1] != null) {
                let mv = parseInt(regArray[1], 10)
                if (mv >= 1000) {
                  mv = mv / 1000
                  ite.t = ite.t
                    .toString()
                    .replace(`${regArray[1]}m`, mv.toString().replace('.', ','))
                }
              } else {
                const regExpmV2 = /(\d+)\smV/

                const regArray2 = regExpmV2.exec(ite.t)
                if (regArray2 != null && regArray2[1] != null) {
                  console.log(
                    'regArray2',
                    regArray2[1],
                    parseInt(regArray2[1], 10)
                  )
                  let mv = parseInt(regArray2[1], 10)
                  if (mv >= 1000) {
                    mv = mv / 1000
                    ite.t = ite.t
                      .toString()
                      .replace(
                        `${regArray2[1]} m`,
                        mv.toString().replace('.', ',')
                      )
                  }
                }
              }
            }
          }

          return ite
        })

        setStatusDetailMsg(lstObjForPopUp)
        //{"f":"status","a":"detail",
        //"p":[{"t":"Connessione dati assente","k":"y"},{"t":"Periferiche funzionanti","k":"g"},{"t":"Ingressi regolari","k":"g"}]}
      } else if (
        message.f == Constant.F_STATUS &&
        message.a == Constant.A_GET
      ) {
        console.log(message)
        setStatusGet(message)
      } else if (
        message.f == Constant.F_OUTPUT_COMAND &&
        message.a == Constant.A_GET_LIST
      ) {
        const outList = typeOutput.map((o: any) => {
          return {label: o.label, value: o.value}
        })

        for (let i = 1; i < outList.length; i++) {
          if (message.p[i - 1] != null && message.p[i - 1].name != null) {
            if (
              message.p[i - 1].name.indexOf('out_0') == -1 &&
              message.p[i - 1].name.indexOf('out_1') == -1 &&
              message.p[i - 1].name.indexOf('out_2') == -1
            ) {
              outList[i].label = message.p[i - 1].name
            }
          }
        }

        setLstOutputs(outList)
      } else if (
        message.f == Constant.F_UPGRADE &&
        message.a == Constant.A_CHECK
      ) {
        const vernum = parseFloat(message.fw.version)
        if (message.file == null || message.file == '') {
          setNextVersion({...message.fw, vernum})
        } else {
          setNextVersionTest({...message.fw, vernum})
        }
        setIsLoadingObj(false)
        return
      } else if (
        message.f == Constant.F_INPUT_SENSOR &&
        message.a == Constant.A_GET_LIST &&
        window.location.href.indexOf('inputs') == -1
      ) {
        setLstInputs(message.inputs)
        //setIsLoadingObj(false)
        return
      }

      if (message.a == Constant.A_GET_LIST) {
        console.log({pathname: usel.pathname, f: message.f, usel})

        //TODO: why I can't use currActionName??
        //why I can't use usel.pathname
        // check on login this function useQuery() {
        if (
          (window.location.href.indexOf('service') >= 0 &&
            message.f == Constant.F_SERVICE_AREA) ||
          (window.location.href.indexOf('users') >= 0 &&
            message.f == Constant.F_USERS) ||
          (window.location.href.indexOf('units') >= 0 &&
            message.f == Constant.F_UNIT) ||
          (window.location.href.indexOf('contacts') >= 0 &&
            message.f == Constant.F_CELL) ||
          (window.location.href.indexOf('timings') >= 0 &&
            message.f == Constant.F_TIMING) ||
          (window.location.href.indexOf('inputs') >= 0 &&
            message.f == Constant.F_INPUT_SENSOR) ||
          (window.location.href.indexOf('outputs') >= 0 &&
            message.f == Constant.F_OUTPUT_COMAND) ||
          (window.location.href.indexOf('commands') >= 0 &&
            message.f == Constant.F_COMMAND) ||
          (window.location.href.indexOf('alarms') >= 0 &&
            message.f == Constant.F_ALARM_SYSTEM) ||
          (window.location.href.indexOf(Constant.PATH_SEQUENCES) >= 0 &&
            message.f == Constant.F_SEQ) ||
          ((window.location.href.indexOf(Constant.F_CAMERAS) >= 0 ||
            window.location.href.indexOf('input_cameras') >= 0) &&
            (message.f == Constant.F_CAMERAS ||
              message.f == Constant.F_IP ||
              message.f == Constant.F_ONVIF ||
              message.f == Constant.F_RTSP)) ||
          window.location.href.indexOf('debug') >= 0
        ) {
          if (message.p.err != null) {
            setGenericErrorMsg(message.p.err)
          } else {
            if (
              (window.location.href.indexOf(Constant.F_CAMERAS) >= 0 ||
                window.location.href.indexOf('input_cameras') >= 0) &&
              message.f == Constant.F_CAMERAS
            ) {
              if (message.cameras != null) {
                for (let i = 0; i < message.cameras.length; i++) {
                  if (
                    message.cameras[i] != null &&
                    message.cameras[i].Inputs != null &&
                    message.cameras[i].Inputs.length >= 1
                  ) {
                    message.cameras[i].inputs_id = []
                    for (
                      let index = 0;
                      index < message.cameras[i].Inputs.length;
                      index++
                    ) {
                      const element = message.cameras[i].Inputs[index]
                      message.cameras[i].inputs_id.push(element.ID)
                    }
                  }
                }

                setGenericList(message.cameras)
              } else {
                setGenericList([])
              }
            } else if (
              window.location.href.indexOf(Constant.F_INPUT_SENSOR) >= 0 &&
              message.f == Constant.F_INPUT_SENSOR
            ) {
              setGenericList(message.inputs)
            } else if (
              window.location.href.indexOf(Constant.F_USERS) >= 0 &&
              message.f == Constant.F_USERS
            ) {
              setGenericList(message.users)
            } else if (
              (message.f == Constant.F_IP ||
                message.f == Constant.F_ONVIF ||
                message.f == Constant.F_ALARM_SYSTEM) &&
              message.a == Constant.A_GET_LIST
            ) {
              console.log('ip or onvif or alarm systems', message)

              setDevicesList(message?.devices)
              setIsLoadingObj(false)
              return
            } else if (
              message.f == Constant.F_RTSP &&
              message.a == Constant.A_GET_LIST
            ) {
              console.log('rtsp', message)

              setRtspUrisList(message?.rtspurl)
              setIsLoadingObj(false)
              return
            } else {
              setGenericList(message.p)
            }
          }
        }
      }

      if (
        message.a == Constant.A_SCREENSHOT &&
        message.f == Constant.F_CAMERAS
      ) {
        setImgGeneric(message.p.name)
      }

      if (message.a == Constant.A_GET && message.f != Constant.F_STATUS) {
        if (message.f == Constant.F_WIFI && message.a == Constant.A_GET) {
          if (message.p.password != null) {
            const lenPwd = parseInt(message.p.password || '0')

            if (lenPwd >= 1) {
              message.p.password = new Array(lenPwd).join('@')
            } else {
              message.p.password = ''
            }
          }
          setIsLoadingObj(false)
          setGenericObj(message.p)
          return
        } else if (message.f == Constant.F_USERS) {
          console.log('users', message, message.users[0])
          message.users[0].PasswordHash = Constant.DEFAULT_USER
          setGenericObj(message.users[0])
          setIsLoadingObj(false)
          return
        } else if (message.f == Constant.F_HORN) {
          if (message.p.signalvol == 1) {
            message.p.signalvol = 1
          } else if (message.p.signalvol == 10) {
            message.p.signalvol = 2
          } else {
            message.p.signalvol = 3
          }
          console.log(message)
        } else if (message.f == Constant.F_TIMING) {
          let sec: number = message.p.sec

          const secM = Math.floor(message.p.sec / 60)
          const max = Math.floor(message.p.max / 60)

          sec = message.p.sec % 60

          message.p = {
            ...message.p,
            secM,
            sec,
            max
          }
        } else if (message.f == Constant.F_OUTPUT_COMAND) {
          let life: number = message.p.life

          const lifeM = Math.floor(message.p.life / 60)

          life = message.p.life % 60

          message.p = {
            ...message.p,
            lifeM,
            life
          }
        } else if (message.f === Constant.F_COMMAND) {
          if (message.p != null && message.p.seqdelay != null) {
            message.p.seqdelay.call = parseSecToMMSS(message.p.seqdelay.call)
            message.p.seqdelay.sms = parseSecToMMSS(message.p.seqdelay.sms)
            message.p.seqdelay.mail = parseSecToMMSS(message.p.seqdelay.mail)
          } else {
            message.p.seqdelay = {call: 0, sms: 0, mail: 0}
          }

          let delay: number = message.p.delay

          const delayM = Math.floor(message.p.delay / 60)

          delay = message.p.delay % 60

          for (let i = 0; i < 4; i++) {
            if (!(message.p.o[i].ty === 0 && message.p.o[i].u === 0)) {
              const out: string =
                (message.p.o[i].ty || 0).toString() +
                (message.p.o[i].u || 0).toString() +
                (message.p.o[i].idx || 0).toString()
              message.p.o[i].idx = parseInt(out, 10)
            }
          }

          message.p = {
            ...message.p,
            delayM,
            delay
          }
        } else if (message.f === Constant.F_SEQ) {
          if (message.p != null && message.p.seqdelay != null) {
            message.p.seqdelay.call = parseSecToMMSS(message.p.seqdelay.call)
            message.p.seqdelay.sms = parseSecToMMSS(message.p.seqdelay.sms)
            message.p.seqdelay.mail = parseSecToMMSS(message.p.seqdelay.mail)
          } else {
            message.p.seqdelay = {call: 0, sms: 0, mail: 0}
          }

          message.p = {
            ...message.p
          }
        } else if (message.f === Constant.F_CONFIG0) {
          const cellexpdate = dayjs(message.p.cellexpdate)

          message.p = {
            ...message.p,
            cellammountmin: message.p.cellammountmin.toString(),
            cellexpdate:
              message.p.cellexpdate != '' ? cellexpdate.toDate() : null
          }

          //se versionESP >= 1.035, allora non ricevo la password in chiaro ma la lunghezza della password in formato stringa es. "16"
          if (message.p.wifipwd != null && message.p.wifipwd.length <= 3) {
            message.p.wifipwd = new Array(
              parseInt(message.p.wifipwd || '0') + 1
            ).join('@')
          }
        }

        if (message.f == Constant.F_CONFIG0) {
          setConfigZeroObj(message.p)
        } else if (message.f == Constant.F_CONFIG) {
          setGenericObj(message.c)
        } else if (message.f == Constant.F_CAMERAS) {
          for (let i = 0; i < message.cameras.length; i++) {
            if (
              message.cameras[i] != null &&
              message.cameras[i].Inputs != null &&
              message.cameras[i].Inputs.length >= 1
            ) {
              message.cameras[i].inputs_id = []
              for (
                let index = 0;
                index < message.cameras[i].Inputs.length;
                index++
              ) {
                const element = message.cameras[i].Inputs[index]
                message.cameras[i].inputs_id.push(element.ID)
              }
            }
          }

          setGenericObj(message.cameras[0])
        } else {
          setGenericObj(message.p)
        }

        setIsLoadingObj(false)
      } else if (
        message.a == Constant.A_SAVE ||
        ((message.f == Constant.F_BACKUP || message.f == Constant.F_RESTORE) &&
          message.a == Constant.A_REMOTE)
      ) {
        setIsSaving(false)
        if (
          message.p != null &&
          (message.p.ret == null || message.p.ret == '')
        ) {
          let msg = 'Salvataggio effettuato con successo'
          if (
            message.f == Constant.F_BACKUP &&
            message.a == Constant.A_REMOTE
          ) {
            msg = 'Backup effettuato con successo'
          }
          if (
            message.f == Constant.F_RESTORE &&
            message.a == Constant.A_REMOTE
          ) {
            msg = 'Ripristino in corso ...'
          }
          setGenericErrorMsg(msg)
        } else if (message.p != null) {
          setGenericErrorMsg(message.p.ret)
        }
      } else if (
        message.f == Constant.F_MEMORY &&
        (message.p.ret == '' || message.p.ret != '')
      ) {
        setIsSaving(false)
        if (
          message.p != null &&
          (message.p.ret == null || message.p.ret == '')
        ) {
          const msg = 'Memoria inviata con successo'

          setGenericErrorMsg(msg)
        } else if (message.p != null) {
          setGenericErrorMsg(message.p.ret)
        }
      } else if (
        (message.a == Constant.A_ADD_TAG &&
          message.p.ret != Constant.START_LEARN) ||
        (message.a == Constant.A_ADD_RADIO_C &&
          message.p.ret != Constant.START_LEARN) ||
        message.a == Constant.A_DEL_TAG ||
        message.a == Constant.A_DEL_RADIO_C ||
        message.a == Constant.A_DEL_RADIO_LEARN ||
        message.a == Constant.A_CHECK_LEARN_RC ||
        message.a == Constant.A_CHECK_LEARN_TAG ||
        (message.f == Constant.F_USERS && message.a == Constant.A_DEL) ||
        message.a == Constant.A_EXCLUDE ||
        message.a == Constant.A_CALL
      ) {
        let msgStr: string = ''

        // TODO: normalize
        if (message.a == Constant.A_DEL_TAG) {
          if (message.p.ret == '') {
            msgStr = 'Chiave cancellata con successo'
          } else {
            msgStr = message.p.ret
          }
        } else if (message.a == Constant.A_DEL_RADIO_C) {
          if (message.p.ret == '') {
            msgStr = 'Radiocomando cancellato con successo'
          } else {
            msgStr = message.p.ret
          }
        } else if (message.a == Constant.A_ADD_TAG) {
          if (message.p.ret == '') {
            msgStr = 'Chiave appresa con successo'
          } else {
            msgStr = `Chiave ${message.p.ret}`
          }
        } else if (message.a == Constant.A_ADD_RADIO_C) {
          if (message.p.ret == '') {
            msgStr = 'Radiocomando appreso con successo'
          } else {
            msgStr = `Radiocomando ${message.p.ret}`
          }
        } else if (message.a == Constant.A_DEL) {
          if (message.p.ret == '') {
            msgStr = 'Utente cancellato con successo'
          } else {
            msgStr = `Problemi nella cancellazione dell'utente ${message.p.ret}`
          }
        } else if (message.a == Constant.A_EXCLUDE) {
          if (message.p.ret == '') {
            msgStr = 'Ingresso escluso/incluso con successo'
          } else {
            msgStr = `Problemi nella esclusione/inclusione del sensore ${message.p.ret}`
          }
        } else if (message.a == Constant.A_DEL_RADIO_LEARN) {
          if (message.p.ret == '') {
            msgStr = 'Apprendimento annullato'
          } else {
            msgStr = `Problemi nell'apprendimento ${message.p.ret}`
          }
        } else if (message.a == Constant.A_CHECK_LEARN_RC) {
          msgStr = message.p.ret
        } else if (message.a == Constant.A_CHECK_LEARN_TAG) {
          msgStr = message.p.ret
        }

        setGenericErrorMsg(msgStr)
      }
    } else {
      //parse push msg
      if (message.f == Constant.F_VERSION && message.a == Constant.A_GET) {
        //DO not CHANGE!!
        message.version.versionSam = 2
        message.version.versionEsp = 2

        const isIT1v2 = false

        message.version.isIT1v2 = isIT1v2
        console.log('version', {...message.version, name: message.p.name})
        setVersion({...message.version, name: message.p.name})
      } else if (
        message.f == Constant.F_STATUS &&
        message.a == Constant.A_GET
      ) {
        setStatusGet(message)
      }
    }

    clearTimeout(timeout)
  }

  // ** Merge Routes
  const Routes = [
    {
      path: '/',
      index: true,
      element: <Navigate replace to={DefaultRoute} />
    },
    {
      path: '/home',
      element: <Home SendCmd={SendCmd} />
    },
    {
      path: '/memory',
      element: <Memory SendCmd={SendCmd} />
    },
    {
      path: '/debug',
      element: <Debug SendCmd={SendCmd} />
    },
    {
      path: '/users',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={Constant.UTENTI_LABEL}
          functName={Constant.F_USERS}
          actionName={Constant.A_GET_LIST}
          isList={true}
          showDelete={false}
          showNew={false}
          maxObjNum={1}
          idx={-1}
          openHelpOnLoad={true}
          loadFirstElement={true}
        />
      )
    },
    {
      path: '/services',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Servizi'}
          functName={Constant.F_SERVICE_AREA}
          actionName={Constant.A_GET_LIST}
          isList={true}
          idx={-1}
          openHelpOnLoad={false}
        />
      )
    },
    // {
    //   path: '/horn',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Sirena a bordo'}
    //       functName={Constant.F_HORN}
    //       actionName={Constant.A_GET}
    //       idx={0}
    //       isList={false}
    //       showDelete={false}
    //       showNew={false}
    //     />
    //   )
    // },
    {
      path: '/maintenance/block',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Blocco'}
          functName={Constant.F_BLOCK}
          actionName={Constant.A_GET}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
        />
      )
    },
    {
      path: '/maintenance/reboot',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Manutenzione'}
          functName={Constant.F_MAINTENANCE}
          actionName={Constant.F_REBOOT}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
        />
      )
    },
    {
      path: `/maintenance/${Constant.MAINTENANCE_UPDATE}`,
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Manutenzione'}
          functName={Constant.F_UPGRADE}
          actionName={Constant.A_CHECK}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
        />
      )
    },
    {
      path: `/maintenance/${Constant.MAINTENANCE_CONFIG}`,
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Manutenzione'}
          functName={Constant.F_MAINTENANCE}
          actionName={Constant.F_BACKUP}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
        />
      )
    },
    // {
    //   path: '/units',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Periferiche'}
    //       functName={Constant.F_UNIT}
    //       actionName={Constant.A_GET_LIST}
    //       idx={-1}
    //       isList={true}
    //       showDelete={true}
    //       showNew={false} //TODO:1 fix
    //       maxObjNum={0}
    //     />
    //   )
    // },
    // {
    //   path: '/contacts',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Rubrica'}
    //       functName={Constant.F_CELL}
    //       actionName={Constant.A_GET_LIST}
    //       idx={0}
    //       isList={true}
    //       showDelete={false}
    //       showNew={false}
    //     />
    //   )
    // },
    // {
    //   path: '/messages',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Messaggio comune'}
    //       functName={Constant.F_MESSAGE}
    //       actionName={Constant.A_GET}
    //       idx={0}
    //       isList={false}
    //       showDelete={false}
    //       showNew={false}
    //     />
    //   )
    // },
    // {
    //   path: '/timings',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Temporizzazioni'}
    //       functName={Constant.F_TIMING}
    //       actionName={Constant.A_GET_LIST}
    //       idx={0}
    //       isList={true}
    //       showDelete={false}
    //       showNew={false}
    //     />
    //   )
    // },
    {
      path: '/inputs',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={Constant.INPUT_LBL}
          functName={Constant.F_INPUT_SENSOR}
          actionName={Constant.A_GET_LIST}
          idx={-1}
          isList={true}
          showDelete={false}
          showNew={false}
          maxObjNum={0}
          openHelpOnLoad={true}
        />
      )
    },
    {
      path: '/input_cameras',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Abbinamento Ingressi Telecamere'}
          functName={Constant.F_CAMERAS}
          actionName={Constant.A_GET_LIST}
          idx={-1}
          isList={true}
          showDelete={false}
          showNew={false}
          maxObjNum={0}
        />
      )
    },
    // {
    //   path: '/outputs',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Uscite'}
    //       functName={Constant.F_OUTPUT_COMAND}
    //       actionName={Constant.A_GET_LIST}
    //       idx={-1}
    //       isList={true}
    //       showDelete={false}
    //       showNew={false}
    //       maxObjNum={0}
    //     />
    //   )
    // },
    {
      path: '/cameras',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Telecamere'}
          functName={Constant.F_CAMERAS}
          actionName={Constant.A_GET_LIST}
          idx={-1}
          isList={true}
          showDelete={true}
          showNew={true}
          maxObjNum={0}
        />
      )
    },
    {
      path: Constant.PATH_ALARM_SYSTEM,
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Abbinamento impianto'}
          functName={Constant.F_ALARM_SYSTEM}
          actionName={Constant.A_GET}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
          maxObjNum={0}
        />
      )
    },
    {
      path: '/wifi',
      element: (
        <GenericListEditing
          SendCmd={SendCmd}
          title={'Rete WiFi'}
          functName={Constant.F_WIFI}
          actionName={Constant.A_GET}
          idx={-1}
          isList={false}
          showDelete={false}
          showNew={false}
          maxObjNum={0}
        />
      )
    },
    // {
    //   path: '/lte',
    //   element: (
    //     <GenericListEditing
    //       SendCmd={SendCmd}
    //       title={'Rete LTE'}
    //       functName={Constant.F_CONFIG0}
    //       actionName={Constant.A_GET}
    //       idx={-1}
    //       isList={false}
    //       showDelete={false}
    //       showNew={false}
    //       maxObjNum={0}
    //     />
    //   )
    // },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/error',
      element: <Error />,
      meta: {
        layout: 'blank'
      }
    }
  ]

  const getRouteMeta = (route: any) => {
    if (isObjEmpty(route.element.props)) {
      if (route.meta) {
        return {routeMeta: route.meta}
      } else {
        return {}
      }
    }
  }

  // ** Return Filtered Array of Routes & Paths
  const MergeLayoutRoutes = (layout: any, defaultLayout: any) => {
    const LayoutRoutes: any = []

    if (Routes) {
      Routes.filter((route: any) => {
        let isBlank = false
        // ** Checks if Route layout or Default layout matches current layout
        if (
          (route.meta && route.meta.layout && route.meta.layout === layout) ||
          ((route.meta === undefined || route.meta.layout === undefined) &&
            defaultLayout === layout)
        ) {
          let RouteTag: any = PublicRoute

          // ** Check for public or private route
          if (route.meta) {
            route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
            RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
          }

          RouteTag = PrivateRoute
          //isObjEmpty(route.element.props) &&
          if (route.element) {
            const Wrapper: any =
              // eslint-disable-next-line multiline-ternary
              isBlank === false
                ? // eslint-disable-next-line multiline-ternary
                  LayoutWrapper
                : Fragment

            route.element = (
              <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
                <RouteTag route={route}>{route.element}</RouteTag>
              </Wrapper>
            )
          }

          // Push route to LayoutRoutes
          LayoutRoutes.push(route)
        }
        return LayoutRoutes
      })
    }
    return LayoutRoutes
  }

  const getRoutes = (layout: any) => {
    const defaultLayout = layout || 'vertical'
    const layouts = ['vertical', 'blank'] //'horizontal',

    const AllRoutes: any = []

    layouts.forEach((layoutItem: any) => {
      const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

      AllRoutes.push({
        path: '/',
        element: getLayout[layoutItem] || getLayout[defaultLayout],
        children: LayoutRoutes
      })
    })
    return AllRoutes
  }

  const allRoutes = getRoutes(layout)

  const getHomeRoute = () => {
    if (isLogged) {
      return '/home'
    } else {
      return '/login'
    }
  }

  const mqttConnectAndSubscribe = async () => {
    if (
      mqttConnectionObj != null &&
      (client == null || !client.isConnected())
    ) {
      const respPubSub = `${mqttConnectionObj.typeAS}/${mqttConnectionObj.mqttTopic}/${uniqueId}/${Constant.MQTT_RESP}`
      const pushPubSub = `${mqttConnectionObj.typeAS}/${mqttConnectionObj.mqttTopic}/${Constant.MQTT_PUSH}`

      client = await ConnectMqtt({
        host: Constant.MQTT_SERVER,
        ssl: true,
        port: Constant.MQTT_PORT,
        path: Constant.MQTT_PATH,
        username: mqttConnectionObj.mqttUser,
        password: mqttConnectionObj.mqttPwd,
        cleanSession: true,
        reconnect: false,
        clientId: `w${mqttConnectionObj.clientID.replace(
          '-',
          '_'
        )}-${generateUUID().substring(0, 6)}`
      })

      client.removeMessageListener(respPubSub)
      client.unsubscribe(respPubSub, true)
      client.subscribe(respPubSub)

      client.removeMessageListener(pushPubSub)
      client.unsubscribe(pushPubSub, true)
      client.subscribe(pushPubSub)

      client.onMessage(respPubSub, async (message: any) => {
        try {
          console.log('this.client.onMessage respPubSub', message)
          //it is better to check if proto or if json instead of alarm type
          //in the future may the re will  be IT0 and IT4
          if (message && message.json) {
            const result = message.json
            console.log(result)

            parseAndUseArrivedMessage(result, 'mqtt', 'resp')
          }
        } catch (error) {
          console.log(error)
        }
      })

      client.onMessage(pushPubSub, async (message: any) => {
        try {
          console.log('this.client.onMessage pushPubSub', message)
          //it is better to check if proto or if json instead of alarm type
          //in the future may the re will  be IT0 and IT4
          if (message && message.json) {
            const result = message.json
            console.log(result)

            parseAndUseArrivedMessage(result, 'mqtt', 'push')
          }
        } catch (error) {
          console.log(error)
        }
      })

      client.on('error', (err: any) => {
        if (err.occurred()) {
          console.log(`error due to an connection error: ${err.message}`)
        }
      })

      client.on('close', (err: any) => {
        if (err.occurred()) {
          console.log(`disconnected due to an connection error: ${err.message}`)
        }
        setIsMqttConnected(false)
      })

      client.on('reconnect', (err: any) => {
        if (err.occurred()) {
          console.log(`reconnect due to an connection error: ${err.message}`)
        }
        setIsMqttConnected(true)
      })

      setIsMqttConnected(true)
    }
  }

  useEffect(() => {
    console.log('useEffect router connect MQTT', mqttConnectionObj, isMqtt)
    async function fetchMyAPI() {
      await mqttConnectAndSubscribe()
    }

    fetchMyAPI()
  }, [isMqtt])

  const routes = useRoutes([
    {
      path: '/',
      index: true,
      element: <Navigate replace to={getHomeRoute()} />
    },

    {
      path: '/login',
      element: <BlankLayout />,
      children: [{path: '/login', element: <Login SendCmd={SendCmd} />}]
    },
    {
      path: '*',
      element: <BlankLayout />,
      children: [{path: '*', element: <Error />}]
    },
    ...allRoutes
  ])

  return routes
}

export default Router
